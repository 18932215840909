import React, { useRef, useState, useEffect } from "react";
import {
  connectAutoComplete,
  Highlight,
  Snippet,
} from "react-instantsearch-dom";
import { usePopper } from "react-popper";
import { useHistory, Link } from "react-router-dom";
import cn from "classnames";
import isHotkey from "is-hotkey";
import {
  NoteIcon,
  BlockResultIcon,
  SearchIcon,
  KbdSearchIcon,
  SidepanelCollapseIcon,
} from "thunk-icons";

import { appShortcuts } from "appShortcuts";
import { PortalBody } from "framework/components/Portal";
import useWindowEventListener from "hooks/useWindowEventListener";
import keyDownHandler from "./keys";
import { useSidebarContext } from "providers/SidebarProvider";
import useOnClickOutside from "hooks/useOnClickOutside";
import { getEditorPathname } from "helpers";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";

import styles from "./index.module.scss";
import Button from "framework/components/form/Button";
import { is } from "ramda";

const SearchInput = ({ currentRefinement, refine, hits }: any) => {
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const targetRef = useRef<HTMLInputElement | null>(null);
  const clearIconRef = useRef<HTMLButtonElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);
  const { isSidebarOpened } = useSidebarContext();

  const { isWindows, isMobileScreen } = useDeviceDetector();
  const metaKey = isWindows ? "Ctrl" : "⌘";

  const [focused, setFocused] = useState(false);
  const [_isVisible, setIsVisible] = useState(false);
  const isVisible =
    _isVisible &&
    !!hits.length &&
    currentRefinement &&
    currentRefinement.trim().length > 1;

  const openAutoSuggest = () => {
    setIsVisible(true);
  };

  const closeAutoSuggest = () => {
    setIsVisible(false);
    targetRef.current.blur();
    setIndex(0);
  };

  const navigateToHit = (hit: any, doOpenInNewTab = false) => {
    const handler = doOpenInNewTab
      ? (path: any) => window.open(`${window.location.origin}${path}`, "_blank")
      : (path: any) => history.push(path);

    const link = getLink(hit);
    handler(link);
  };

  const getLink = (hit: any) => {
    const id = hit.pageType === "page" ? hit.pageId : hit.title;
    const isBlock = hit._highlightResult.title.matchLevel == "none";
    const blockId = isBlock ? hit.objectID : "";
    return getEditorPathname(hit.pageType, id, hit.title, blockId);
  };

  useWindowEventListener(
    "keydown",
    (event: any) => {
      if (isVisible) {
        keyDownHandler(
          event,
          hits,
          targetRef,
          index,
          setIndex,
          navigateToHit,
          closeAutoSuggest
        );
      }
    },
    [hits, index, setIndex, navigateToHit, isVisible]
  );

  useWindowEventListener(
    "keydown",
    (e: any) => {
      if (isHotkey(appShortcuts.search, e)) {
        e.preventDefault();
        if (targetRef.current) {
          targetRef.current.focus();
          targetRef.current.select();
        }
      }
    },
    [isSidebarOpened]
  );

  useOnClickOutside([targetRef, popperRef], closeAutoSuggest);

  const popperProps = usePopper(targetRef.current, popperRef.current, {
    strategy: "fixed",
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [-100, 10],
        },
      },
    ],
  });
  const { styles: popperStyles, attributes } = popperProps;

  useEffect(() => {
    popperProps.update && popperProps.update(); // update popper after sidebar toggles

    if (!isSidebarOpened && targetRef.current) {
      targetRef.current.blur();
    }
  }, [isSidebarOpened]);

  const clearInput = () => {
    refine("");
  };

  return (
    <>
      <div
        className={cn(styles.searchInputContainer, {
          [styles.containerFocused]: focused,
          [styles.hasValue]: !!currentRefinement,
          [styles.isEmpty]: !currentRefinement,
        })}
        onClick={(e) => {
          targetRef.current.focus();

          // selects all text within search bar when focused
          targetRef.current.setSelectionRange(
            0,
            targetRef.current.value.length
          );
        }}
      >
        <SearchIcon className={styles.searchIcon} size={18} />
        {!isMobileScreen && (
          // <div className={styles.shortcut}>{`${metaKey}+p`}</div>
          // TO DO Make a different Icon appear conditionally based on metaKey
          <KbdSearchIcon className={styles.keyboardHint} size={62} />
        )}

        <Button
          ref={clearIconRef}
          onClick={clearInput}
          btnClass={cn(styles.clearButton, {
            [styles.visibleClearButton]: isVisible,
          })}
          icon={SidepanelCollapseIcon}
          variant="circularElectronHeader"
          size="icon"
        ></Button>
        <input
          ref={targetRef}
          name="pageSearch"
          className={styles.searchInput}
          type="search"
          autoComplete="off"
          spellCheck="false"
          value={currentRefinement}
          onChange={(e) => refine(e.currentTarget.value)}
          placeholder={"Search"}
          onKeyDown={(e) => isHotkey("Escape", e) && targetRef.current.blur()}
          onFocus={() => {
            setFocused(true);
            openAutoSuggest();
          }}
          onBlur={() => setFocused(false)}
        />
      </div>

      <PortalBody>
        <div
          ref={popperRef}
          style={popperStyles.popper}
          {...attributes.popper}
          className={cn(styles.menuContainer, {
            [styles.visible]: isVisible,
          })}
        >
          {hits.map((hit: any, i: any) => {
            return (
              <Link
                key={hit.objectID}
                className={cn(styles.menuItem, {
                  [styles.focused]: i === index,
                })}
                to={getLink(hit)}
                onClick={(e: any) => {
                  if (!e.metaKey) {
                    closeAutoSuggest();
                  }
                }}
              >
                <div className={styles.menuItemText}>
                  <div className={styles.menuItemIcon}>
                    <NoteIcon
                      className={cn(styles.visibleIcon, {
                        [styles.invisibleIcon]:
                          hit._highlightResult.title.matchLevel == "none", // hide this is there is no title match
                      })}
                      size={20}
                    />
                    <BlockResultIcon
                      className={cn(styles.visibleIcon, {
                        [styles.invisibleIcon]: !(
                          hit._highlightResult.title.matchLevel == "none"
                        ), // show this is there is no title match
                      })}
                      size={20}
                    />
                  </div>
                  <div className={styles.menuItemResultText}>
                    <div className={styles.menuItemResultTitle}>
                      <Highlight attribute="title" hit={hit} tagName="mark" />
                    </div>
                    <div className={styles.menuItemResultTextContent}>
                      <Snippet
                        attribute="textContent"
                        hit={hit}
                        tagName="mark"
                      />
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </PortalBody>
    </>
  );
};

export default connectAutoComplete(SearchInput);
